import React, { useState, useEffect } from "react";
import Link from "next/link";
import { getLocalStorageItem, CONSTANTS } from "@util/utils";
import { colors, viewport } from "@styles/cssinjs/helpers/variables";

type SocialSignUpProps = {
  data: {
    textBeforeButton: string;
    buttonLeftIcon: string;
    buttonLeftText: string;
    inBetweenText: string;
    buttonRightIcon: string;
    buttonRightText: string;
  };
  containerClass: string;
  lang: string;
};

export default function SocialSignUp({
  data,
  containerClass,
  lang,
}: SocialSignUpProps) {
  const [socialSigninParams, setSocialSigninParams] = useState<string | null>(
    null
  );
  const appMode = process.env.APP_MODE || "staging";
  const url =
    appMode === "live" ? CONSTANTS.live.appUrl : CONSTANTS.staging.appUrl;

  useEffect(() => {
    const signUpParams = `signUpPage=${getLocalStorageItem(
      "createdFrom"
    )}&languageChosenFromWeb=${lang}&ref=${getLocalStorageItem(
      "ref"
    )}&isContainerApp=true`;

    setSocialSigninParams(signUpParams);
  }, [lang]);

  const googleSignup = `${url}/oauth2callback.do?${socialSigninParams}`;
  const facebookSignup = `${url}/facebookOauthCallback.do?${socialSigninParams}`;

  return (
    <div className={`signup-container ${containerClass}`}>
      <span className="signup-text text-xs ff-inter">
        {data.textBeforeButton}{" "}
      </span>
      <Link
        href={googleSignup}
        className="signup-button fs-0 fx--cv bg-dull-grey"
        prefetch={false}
      >
        <span
          className="signup-icon"
          dangerouslySetInnerHTML={{ __html: data.buttonLeftIcon }}
        ></span>
        <span className="signup-platform text-xs ff-inter">
          {data.buttonLeftText}
        </span>
      </Link>
      <span className="signup-text text-xs ff-inter">
        {" "}
        {data.inBetweenText}{" "}
      </span>
      <Link
        href={facebookSignup}
        className="signup-button fs-0 fx--cv bg-dull-grey"
        prefetch={false}
      >
        <span
          className="signup-icon"
          dangerouslySetInnerHTML={{ __html: data.buttonRightIcon }}
        ></span>
        <span className="signup-platform text-xs ff-inter">
          {data.buttonRightText}
        </span>
      </Link>
      <style global jsx>{`
        .signup-button {
          display: inline-flex;
          gap: 2px;
          padding: 4px 8px;
          border-radius: 14px;
          border: none;
        }
        .signup-container {
          display: flex;
          align-items: center;
          gap: 2px;
        }
        .signup-text {
          color: ${colors.primitive.grey[300]};
        }
        .signup-button > .signup-platform {
          line-height: 20px;
          color: ${colors.derived.brand};
        }
        @media (max-width: ${viewport.mobile.md}) {
          .signup-container {
            justify-content: center;
          }
        }
      `}</style>
    </div>
  );
}
