import { useState, useEffect, useRef } from "react";
import Script from "next/script";
import {
  StyledInput,
  StyledSubmit,
  StyledError,
} from "../../../../styles/cssinjs/components/StyledForm";
import { allErrorMessages } from "../../../../util/forms/data";
import { setLocalStorageItem } from "../../../../util/utils";
import validate from "../../../../util/forms/validation";
import useAutoFocus from "../../../../util/forms/input-autofocus";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "../../../../styles/cssinjs/helpers/variables.js";
import SocialSignUp from "../../../molecules/common/SocialSignUp";

export default function CustomEmailInput({
  action,
  data,
  isAutoFocus = true,
  socialSignUpData,
  socialSignupContainerClass = "",
  lang,
  submitButtonId,
  id,
  ...props
}) {
  const [email, setEmail] = useState("");
  const [dynamicHref, setDynamicHref] = useState(action);
  const emailInputRef = useAutoFocus(isAutoFocus);
  const updateHrefWithEncodedEmail = (email) => {
    const encodedEmail = btoa(JSON.stringify({ email }));
    return `${action}?contact=${encodeURIComponent(encodedEmail)}`;
  };
  useEffect(() => {
    if (email) {
      setDynamicHref(updateHrefWithEncodedEmail(email));
    }
  }, [email]);
  return (
    <>
      <div className={`jsx-form lg:mt-5 md:mt-4 index-form`} {...props}>
        <div className="email-cont lg:mr-2 md:mb-2">
          <StyledInput>
            <input
              data-testid="indexEmailFooterForm"
              id={id}
              type={data.fields.email.type}
              name={data.fields.email.name}
              placeholder={data.fields.email.placeholder}
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              className="index-email"
              ref={emailInputRef}
              aria-label="Enter email to sign up."
            />
          </StyledInput>
          <svg
            className="input-loader"
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.92893 4.92893C4.09178 3.76609 5.52345 2.90797 7.09715 2.4306C8.67085 1.95322 10.338 1.87132 11.9509 2.19215C13.5638 2.51297 15.0727 3.22663 16.3439 4.26989C17.6152 5.31316 18.6095 6.65384 19.2388 8.17316C19.8681 9.69249 20.113 11.3436 19.9518 12.9802C19.7907 14.6168 19.2283 16.1883 18.3147 17.5557C17.4011 18.9231 16.1643 20.044 14.714 20.8192C13.2636 21.5944 11.6445 22 10 22"
              stroke="#1D90F5"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <StyledSubmit>
          <a
            href={dynamicHref}
            className={`jsx-btn-primary fx fx--cv`}
            aria-label="Click to sign up"
          >
            {data.submit.text}
          </a>
        </StyledSubmit>
        <style global jsx>{`
          .email-cont {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 100%;
            vertical-align: middle;
            max-width: 300px;
            padding-right: 45px;
            border-radius: 8px;
            background-color: ${colors.primitive.white.default};
            box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
              0 1px 3px 0 rgba(48, 58, 71, 0.1);
          }
          .email-cont:hover {
            box-shadow: 0 10px 20px rgba(48, 58, 71, 0.15),
              0 3px 6px rgba(48, 58, 71, 0.08);
          }
          .email-cont:focus-within {
            box-shadow: 0 10px 20px rgba(48, 58, 71, 0.15),
              0 3px 6px rgba(48, 58, 71, 0.08);
          }
          .email-cont:focus-within .index-email {
            color: ${colors.primitive.grey[800]};
          }
          .email-cont .index-email {
            width: 100%;
            border: none;
            padding: 15px 0px 7px 24px;
            background-color: ${colors.primitive.white.default};
            font-size: ${font.size.sm[4]};
            border-radius: 8px;
            line-height: ${lineHeight[7]};
            color: ${colors.primitive.grey[300]};
          }
          .email-cont .index-email ::placeholder {
            font-size: ${font.size.sm[4]};
            line-height: ${lineHeight[7]};
            color: ${colors.primitive.grey[300]};
          }
          .email-cont .input-loader {
            display: none;
            width: 22px;
            position: absolute;
            right: 24px;
            top: 14px;
            height: 22px;
          }
          .input-check .input-loader {
            display: inline-block;
            animation: spin 1.2s linear infinite;
          }
          .input-check .btn-primary {
            opacity: 0.3;
            pointer-events: none;
          }
          .input-check .index-email {
            color: ${colors.primitive.grey[300]};
          }
          .index-form {
            display: flex;
            align-items: center;
          }
          .index-form .jsx-btn-primary {
            width: 140px;
            height: 50px;
            margin-top: 0px;
          }
          @media (max-width: ${viewport.tablet.sm}) {
            .index-form {
              display: block;
            }
            .email-cont {
              max-width: 100%;
            }
            .index-form .jsx-btn-primary {
              width: 100%;
            }
          }
        `}</style>
      </div>
      {socialSignUpData && (
        <SocialSignUp
          data={socialSignUpData}
          containerClass={socialSignupContainerClass}
          lang={lang}
        />
      )}
      <Script
        src="https://assets.setmore.com/website/next/scripts/vendor/validate.js"
        strategy="afterInteractive"
      />
    </>
  );
}
