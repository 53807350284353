import { useCallback, useRef } from "react";

export default function useAutoFocus(
  isAutoFocus = true,
  returnPersistentRef = false
) {
  const persistentRef = useRef(null);

  const refCallback = useCallback(
    (inputElement) => {
      if (inputElement && isAutoFocus) {
        inputElement.focus();
      }
      persistentRef.current = inputElement; // Store the input element in the ref
    },
    [isAutoFocus]
  );

  if (returnPersistentRef) {
    return { callback: refCallback, ref: persistentRef };
  }

  return refCallback;
}
